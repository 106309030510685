<template>
  <div class="container-fluid" style="margin-top: 1rem; color: white">
    <div class="col" style="padding: 12px" v-if="currentPages > 1">
      Sayfa : {{ currentPages }}
    </div>

    <div
      class="row"
      v-masonry
      transition-duration="0.3s"
      item-selector=".mono"
      v-if="popularMovies.count > 0"
    >
      <transition-group name="slide-fade">
        <!---->

        <div
          class="col l3 m6 s12 mono"
          v-for="(item, i) in popularMovies.data"
          :key="i"
          v-masonry-tile
          style="margin-bottom: 10px"
        >
          <div class="bg-blue-x card darken-1">
            <div class="card-wrap">
              <div
                :style="{
                  'background-image': 'url(' + item.img + ')',
                }"
                class="card-img"
              ></div>
              <!-- <img
                class="card-img"
                :src="item.img"
                alt=""
                onerror="if (this.src != 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7') this.src = 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';"
              /> -->
              <div class="card-content white-text">
                <span class="card-title">{{ item.name }}</span>
                <p style="color: #f9ffff7d">
                  {{ item.description.substr(0,150)+"..." }}

                </p>
              </div>
              <div class="card-action">
                <router-link
                  :to="{ name: 'movies', params: { id: item.id } }"
                  custom
                  v-slot="{ navigate, href }"
                >
                  <a @click="navigate" :href="href"
                    ><i
                      class="fa fa-eye"
                      style="color: white; padding-right: 5px"
                    ></i>
                    VİDEOYU AÇ
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
    <br />

    <div style="justify-content: center; display: flex; margin-bottom: 2rem">
      <a
        class="btn-floating btn-large waves-effect waves-light bg-blue-x"
        style="margin-right: 2px"
        v-if="currentPages > 1"
        @click="preventPage"
      >
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </a>
      <a
        class="btn-floating btn-large waves-effect waves-light bg-blue-x"
        style="margin-right: 2px"
        @click="nextPage"
        v-if="!(popularMovies.data.length < 10)"
      >
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPages: 0,
    };
  },
  created() {
    this.$store.dispatch("popularMovies", this.currentPages);
  },
  computed: {
    popularMovies() {
      return this.$store.getters.getPopularMovies;
    },
  },
  updated() {
    this.$redrawVueMasonry();
  },
  filters: {
    shortOwerview: function (value) {
      if (value == "") {
        return "";
      } else {
        return value.substring(0, 80) + "...";
      }
    },
  },
  methods: {
    async nextPage() {
      window.scrollTo(0, 0);
      this.currentPages++;
      this.$store.dispatch("popularMovies", this.currentPages);
    },
    async preventPage() {
      window.scrollTo(0, 0);
      this.currentPages--;
      this.$store.dispatch("popularMovies", this.currentPages);
    },
  },
};
</script>
<style scoped>
.bg-blue-x {
  background-color: #2a3253 !important;
}
.card-wrap {
  overflow: hidden;
  position: relative;
}

.card-img {
  /* opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto; */
  width: 100%;
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-content {
  position: relative;
}
</style>
