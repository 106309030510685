import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    popularMovies: [],
    moviesDetails: [],
  },
  mutations: {
    popularMovies(state, fields) {
      state.moviesDetails = [];
      state.popularMovies = fields;
    },
    moviesDetails(state, fields) {
      state.moviesDetails = fields;
    },
  },
  actions: {
    popularMovies(context, pagesNo) {
      axios
        .get(
          `https://apaipapiapia222.2023campaignbnnace.com/list/?start=${pagesNo}`
        )
        .then((response) => {
          console.log(response.data.data)
          context.commit("popularMovies", {
            data: response.data.data,
            count: response.data.data.length,
          });
        });
    },
    moviesDetails(context, movieID) {
      axios
        .get(
          `https://apaipapiapia222.2023campaignbnnace.com/get/?id=${movieID}`
        )
        .then((response) => {
          context.commit("moviesDetails", response.data);
        })
        .catch(() => {
          context.commit("moviesDetails", { success: "false" });
        });
    },
  },
  getters: {
    getPopularMovies(state) {
      return state.popularMovies;
    },
    getMoviesDetails(state) {
      return state.moviesDetails;
    },
  },
});
export default store;
