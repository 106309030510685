<template>
  <div style="color: white">
    <div class="movie-wrap" v-if="moviesDetails.success != 'false'">
      <!-- <img
        class="movie-bg"
        :src="
          'https://image.tmdb.org/t/p/original/' + moviesDetails.backdrop_path
        "
        alt=""
        onerror="if (this.src != 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7') this.src = 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';"
      /> -->
      <div class="movie-content">
        <div class="container" style="padding-top: 4rem">
          <h5>{{ moviesDetails.name }}</h5>
          <iframe
            :src="moviesDetails.iframeSrc"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            style="
              width: 100%;
            
              margin-top: 3rem;
              margin-bottom: 1rem;
            "
            class="ifrmaee"
          ></iframe>
          <p style="margin-top: 2rem; font-size: 1.1rem; padding-bottom: 1rem">
            {{ moviesDetails.description }}
          </p>
        </div>
      </div>
    </div>
    <div v-else style="text-align: center; padding: 3rem 0">
      Aradığınız şey Bulunamadı
      <router-link to="/" custom v-slot="{ navigate, href }">
        <a @click="navigate" :href="href">Ana Sayfaya Geri Dön</a>
      </router-link>
    </div>
    <section class="comments container" aria-labelledby="comment">
      <br><br><br><br>
      <h5 id="comment">Comments</h5><br><br><br><br>
      <Disqus />
    </section>
  </div>
</template>
<script>
export default {
  created() {
    this.$store.dispatch("moviesDetails", this.$route.params.id);
  },
  computed: {
    moviesDetails() {
      return this.$store.getters.getMoviesDetails;
    },
  },
  metaInfo() {
    return {
      title: this.moviesDetails.title,
    };
  },
};
</script>
<style>
.movie-wrap {
  overflow: hidden;
  position: relative;
  min-height: 100vh;
}

.movie-bg {
  opacity: 0.05;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 600px;
  height: 100%;
}

.movie-content {
  position: relative;
}
@media (min-width:800px){
  .ifrmaee{
    height: 400px;
  }
}
@media (max-width:790px){
  .ifrmaee{
    height: 300px;
  }
}
</style>
